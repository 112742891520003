<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { deleteAction, getAction } from '@/command/netTool'
import { Card, Col, Modal, Row } from 'ant-design-vue'
import Video from 'quill-video-image-module/video'
export default {
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
      showModal: false,
      modalParams: {
        bannerUrl: '',
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal() {
      return new Promise((resolve) => {
        if (this.totalData) {
          resolve(this.totalData)
        } else {
          getAction('/reportForm/getCompletedOrderTotal').then((e) => {
            this.totalData = e.data
            resolve(e.data)
          })
        }
      })
    },
    getSum(list) {
      let orderCount = 0
      let productQuantityCompleted = 0
      let productQuantity = 0
      let totalAmount = 0
      let totalAmountCompleted = 0
      list.forEach((e) => {
        orderCount += e.orderCount || 0
        productQuantityCompleted += e.productQuantityCompleted || 0
        productQuantity += e.productQuantity || 0
        totalAmount += parseFloat(e.totalAmount) || 0
        totalAmountCompleted += e.totalAmountCompleted || 0
      })
      return {
        nickname: '合计',
        orderCount,
        productQuantityCompleted,
        productQuantity,
        totalAmount,
        totalAmountCompleted,
      }
    },
    getList(params = this.params) {
      getAction('/farmRecommendCommunity/page', params).then((e) => {
        this.records = e.data['records']
        this.params['current'] = e.data['current']
        this.params['pageSize'] = e.data['pageSize']
        this.current = e.data['current']
        this.total = e.data['total']
      })
    },
    getHeader() {
      return [
        {
          type: 'input',
          name: '名称',
          key: 'nickname',
          placeholder: '请输入发布人名称',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '标题',
          dataIndex: 'title',
        },
        {
          title: '发布人',
          dataIndex: 'nickname',
        },
        {
          title: '来源',
          dataIndex: 'sourceType',
          customRender: (text) => {
            return ['平台', '个人'][text]
          },
        },
        {
          title: '浏览量',
          dataIndex: 'viewsQuantity',
        },
        {
          title: '点赞量',
          dataIndex: 'likesQuantity',
        },
        {
          title: '评论数',
          dataIndex: 'commentQuantity',
        },
        {
          title: '收藏数',
          dataIndex: 'collectionQuantity',
        },
        {
          title: '发布时间',
          dataIndex: 'publishTime',
        },

        {
          dataIndex: 'a1',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '详情',
                onClick: () => {
                  getAction(`/farmRecommendCommunity/detail?id=${records.id}`).then((e) => {
                    this.showModal = true
                    this.modalParams = e.data
                  })
                },
              },
              {
                display: true,
                name: '删除',
                popTitle: '确认是否删除',
                type: 'pop',
                onClick: () => {
                  deleteAction('/farmRecommendCommunity/deleteBatch', { idList: [records.id] }, '/api').then(() => {
                    this.$message.success('删除成功')
                    this.getList({ current: 1 })
                  })
                },
              },
            ]
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getButton() {
      return [
        {
          name: '添加',
          onClick: () => {
            this.$router.push('/seeding/add')
          },
        },
      ]
    },
    renderModal() {
      const data = [
        {
          name: '发布人',
          value: this.modalParams.nickname,
        },
        {
          name: '发布时间',
          value: this.modalParams.publishTime,
        },
        {
          name: '标题',
          value: this.modalParams.title,
        },
        {
          name: '正文描述',
          value: this.modalParams.content,
          style: {
            flexDirection: 'column',
          },
          valueStyle: {
            marginLeft: '0px',
          },
        },
      ]
      const data2 = [
        {
          name: '浏览量',
          value: this.modalParams.viewsQuantity,
        },
        {
          name: '点赞数',
          value: this.modalParams.likesQuantity,
        },
        {
          name: '评论数',
          value: this.modalParams.commentQuantity,
        },
        {
          name: '收藏数',
          value: this.modalParams.collectionQuantity,
        },
      ]
      const urls = this.modalParams.bannerUrl.split(',')
      return (
        <Modal v-model={this.showModal} width={600} title="详情">
          {data.map((e) => {
            return (
              <div style={{ display: 'flex', alignItems: 'flex-start', ...(e.style || {}) }}>
                <span>{e.name}:</span>
                <span style={{ marginLeft: '10px', ...(e.valueStyle || {}) }}>{e.value}</span>
              </div>
            )
          })}
          <Row gutter={30}>
            {data2.map((e) => {
              return (
                <Col span={12}>
                  <Card style={{ marginTop: '20px' }}>
                    <div>{e.name}</div>
                    <div>{e.value}</div>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <div style={{ overflowX: 'scroll', marginTop: '20px', display: 'flex', alignItems: 'center' }}>
            {urls.map((e) => {
              if (e.indexOf('mp4') !== -1) {
                return (
                  <video
                    controls={true}
                    src={e}
                    style={{ width: '300px', height: '300px', marginRight: '10px', objectFit: 'fill' }}
                  />
                )
              } else {
                return <img src={e} style={{ width: '300px', height: '300px', marginRight: '10px' }} />
              }
            })}
          </div>
          <template slot="footer">
            <a-button key="back" onClick={() => (this.showModal = false)}>
              取消
            </a-button>
          </template>
        </Modal>
      )
    },
  },
  render() {
    return (
      <div>
        <TemplateHeaderTableNew
          records={this.records}
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          onChange={this.onChange}
          button={this.getButton()}
        />
        {this.renderModal()}
      </div>
    )
  },
}
</script>
